'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.users.factory:UserRoleManager

 # @description

###
angular
  .module 'mundoAdmin.users'
  .factory 'UserRoleManager', [
    'Restangular'
    'UserPermissionsManager'
    'MundoDefaultManager'
    '$translate'
    '$log'
    '_'
    (
      Restangular
      UserPermissionsManager
      MundoDefaultManager
      $translate
      $log
      _
    )->
      UserRoleManagerBase = new MundoDefaultManager()

      UserRoleManagerBase.setUrl('roles')
      UserRoleManagerBase.setAutoTenant()
      UserRoleManagerBase.setNewObject([
        'label'
        'comments'
        'permissions'
        'childRoles'
        'availableForUnits'
        'grantNewPermissions'
      ])
      UserRoleManagerBase.setActions(['list', 'create', 'search', 'update', 'delete'])
      UserRoleManagerBase.setSearchFields(['label'])

      pluckData = (arrayToBePlucked, value) ->
        return _.pluck arrayToBePlucked, value

      UserRoleManagerBase.new = (data) ->
        data.permissions = pluckData data.permissions, 'permission'
        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()), {}, autoTenant: @conf.autoTenant)

      UserRoleManagerBase.update = (id, data) ->

        data.permissions = pluckData data.permissions, 'permission'
        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))

      UserRoleManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/users/views/user-role-detail.tpl.html'

      UserRoleManagerBase.getDetailModalTemplateUrl = ->
        'mundo-admin/users/views/user-role-detail-modal.tpl.html'

      UserRoleManagerBase.getNewForm = ->
        inheritedPermList = []

        getInheritedPermissions = (childroles) ->
          inheritedPermList.splice(0,inheritedPermList.length)
          for role in childroles
            Restangular.service('services/roles/' + role + '/permissions').getList().then(
              (results) ->
                inheritedPermList.push permission.permission for permission in results
                #console.log(inheritedPermList)
              ,
              (error) -> $log.warn("couldn't reach services/roles/")
            )

        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: $translate.instant 'datatable.label.label'
            placeholder: $translate.instant 'datatable.label.label'
            required: true
        ,
          key: 'comments'
          name: 'comments'
          type: 'input'
          templateOptions:
            label: $translate.instant 'datatable.label.comments'
            placeholder: $translate.instant 'datatable.label.comments'
            required: false
        ,
          key: 'availableForUnits'
          name: 'availableForUnits'
          type: 'checkbox'
          templateOptions:
            label: $translate.instant 'datatable.label.availableForUnits'
        ,
          key: 'grantNewPermissions'
          name: 'grantNewPermissions'
          type: 'checkbox'
          templateOptions:
            label: $translate.instant 'datatable.label.grantNewPermissions'
        ,
          key: 'childRoles'
          name: 'childRoles'
          type: 'childrolesDropdown'
          validators: {
            update: (childroles) ->
              getInheritedPermissions(childroles)
              return true
          }
          templateOptions:
            label: $translate.instant 'datatable.label.inheritsFrom'
            placeholder: $translate.instant 'datatable.label.inheritsFrom'
            multiple: true
            required: false
            labelProp: 'label'
            options: @getFullList({'sort': 'label,ASC'}).$object
        ,
          key: 'permissionFilters'
          name: 'permissionFilters'
          type: 'permissionFilters'
          templateOptions:
            label: $translate.instant 'app.permissions.filters'
        ,
          key: 'permissions'
          name: 'permissions'
          type: 'permissionlist'
          templateOptions:
            label: $translate.instant 'datatable.label.permissions'
            placeholder: $translate.instant 'datatable.label.permissions'
            multiple: true
            labelProp: "permission"
            valueProp: "permission"
            options: UserPermissionsManager.getFullList({'sort': 'permission,ASC'}).$object
            inheritedPermList: inheritedPermList
        ]

      UserRoleManagerBase.getEditForm = (data) ->

        permissionsArray = []
        UserPermissionsManager.getFullList({'sort': 'permission,ASC'}).then (possiblePermissions) ->
          angular.forEach data.permissions, (value) ->
            permissionToAdd = _.filter possiblePermissions, (permission) ->
              return permission.permission == value
            if (permissionToAdd) && (angular.isArray permissionToAdd) && (permissionToAdd.length > 0)
              permissionsArray.push {'permission': permissionToAdd[0].permission}

        defaultChildRoleIds = (childroles) ->
          idlist = []
          idlist.push(child.id) for child in childroles
          getInheritedPermissions(idlist)
          return idlist

        inheritedPermList = []

        getInheritedPermissions = (childroles) ->
          inheritedPermList.splice(0,inheritedPermList.length)
          for role in childroles
            Restangular.service('services/roles/' + role + '/permissions').getList().then(
              (results) ->
                inheritedPermList.push permission.permission for permission in results
                #console.log(inheritedPermList)
              ,
              (error) -> $log.warn("couldn't reach services/roles/")
            )



        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: $translate.instant 'datatable.label.label'
            placeholder: $translate.instant 'datatable.label.label'
            required: true
        ,
          key: 'comments'
          name: 'comments'
          type: 'input'
          defaultValue: data.comments
          templateOptions:
            label: $translate.instant 'datatable.label.comments'
            placeholder: $translate.instant 'datatable.label.comments'
            required: false
        ,
          key: 'availableForUnits'
          name: 'availableForUnits'
          type: 'checkbox'
          defaultValue: data.availableForUnits
          templateOptions:
            label: $translate.instant 'datatable.label.availableForUnits'
        ,
          key: 'grantNewPermissions'
          name: 'grantNewPermissions'
          type: 'checkbox'
          defaultValue: data.grantNewPermissions
          templateOptions:
            label: $translate.instant 'datatable.label.grantNewPermissions'
        ,
          key: 'childRoles'
          name: 'childRoles'
          type: 'childrolesDropdown'
          defaultValue: defaultChildRoleIds(data.childRoles)
          validators: {
            update: (childroles) ->
              getInheritedPermissions(childroles)
              return true
          }
          templateOptions:
            label: $translate.instant 'datatable.label.inheritsFrom'
            placeholder: $translate.instant 'datatable.label.inheritsFrom'
            multiple: true
            required: false
            labelProp: 'label'
            options: @getFullList({'sort': 'label,ASC'}).$object
        ,
          key: 'permissionFilters'
          name: 'permissionFilters'
          type: 'permissionFilters'
          templateOptions:
            label: $translate.instant 'app.permissions.filters'
        ,
          key: 'permissions'
          name: 'permissions'
          type: 'permissionlist'
          defaultValue: permissionsArray
          templateOptions:
            label: $translate.instant 'datatable.label.permissions'
            placeholder: $translate.instant 'datatable.label.permissions'
            required: false
            multiple: true
            labelProp: 'permission'
            valueProp: 'permission'
            options: UserPermissionsManager.getFullList({'sort': 'permission,ASC'}).$object
            childRolesKey: 'childRoles' #need this key to automatically check inherited permissions
            inheritedPermList: inheritedPermList
        ]

      UserRoleManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'comments'
          title: 'datatable.label.comments'
          sort: 'comments'
        ,
          key: 'availableForUnits'
          title: 'datatable.label.availableForUnits'
          sort: 'availableForUnits'
          type: 'boolean'
        ,
          key: 'grantNewPermissions'
          title: 'datatable.label.grantNewPermissions'
          sort: 'grantNewPermissions'
          type: 'boolean'
        ]

      #permission needed to edit/delete an entity
      UserRoleManagerBase.editPermission = 'manage all MundoMosaUserBundle:Role entities'

      UserRoleManagerBase
  ]
